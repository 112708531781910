import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
//import VenueHierarchyService, { VenueHierarchyStatus } from "../../../services/VenueHierarachyService";
import { LoadPanel } from "devextreme-react";
import { DataGridOnSelectionChanged, TextBoxOnValueChangedEvent, onExportingEvent } from "../../../types/DevExtremeTypes";
import DataGrid, { Pager, Paging, FilterRow, Column, Sorting, Editing, SearchPanel, FilterPanel, Selection, Export } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import MatrixGridService, { BulkInviteMatchingRowItem, BulkInviteRowItem, BulkInviteContactResponse, BULK_FLOW } from "../../../services/MatrixService";

interface MatchingBulkInviteGridProps extends WithTranslation {
    findMatchesClicked: boolean,
    pastedDetails: BulkInviteRowItem[],
    updateFoundRecords: (matchedGridSource: any[], pastedGridSource: any[]) => void;
    // setSelectedRows: (selectedRows: any, invitesUsers:any,missingUsers: any) => void;
    setSelectedRows: (selectedRows: any) => void;
    navigateFrom: string;
    reloadMatchingGrid: boolean,
    userResponseData: any[]
}

interface MatchingBulkInviteGridState {
    matchingDataSource: BulkInviteMatchingRowItem[],
    loadPanelVisible: boolean,
    contactDetails: []
}

class MatchingBulkInviteGrid extends Component<MatchingBulkInviteGridProps> {
    state: MatchingBulkInviteGridState;
    //venueHierarchyService: VenueHierarchyService;
    matrixGridService: MatrixGridService;
    dataGridRef: any;
    selectionRef: any;
    constructor(props: MatchingBulkInviteGridProps) {
        super(props);
        this.state = {
            matchingDataSource: [],
            loadPanelVisible: false,
            contactDetails: []
        }
        //this.venueHierarchyService = new VenueHierarchyService();
        this.matrixGridService = new MatrixGridService();
        this.dataGridRef = null;
        this.selectionRef = React.createRef();
        this.selectionRef.current = {
            checkBoxUpdating: false,
            selectAllCheckBox: null
        };
    }

    componentDidMount() {
        this.fetchContactDetails();
        // this.dataGridRef?.instance?.selectAll();
        // this.props.updateFoundRecords(this.state.matchingDataSource,this.props.pastedDetails);
    }

    componentDidUpdate(prevProps: MatchingBulkInviteGridProps, prevState: MatchingBulkInviteGridState) {
        if ((this.props.findMatchesClicked !== prevProps.findMatchesClicked && this.props.findMatchesClicked == true)) {
            this.fetchContactDetails();
        } else if (this.props.reloadMatchingGrid != prevProps.reloadMatchingGrid && this.props.reloadMatchingGrid) {
            //this.setState({matchingDataSource:this.props.userResponseData})
            this.reloadMatchingGridWithUpdatedValues();
        }
    }

    reloadMatchingGridWithUpdatedValues = () => {
        let reloadContactData = this.props.userResponseData || []
        let matchedRecords: BulkInviteMatchingRowItem[] = [];
        let updatedPastedSource = this.props.pastedDetails;

        let newPastedSource: any[] = this.props.pastedDetails;
        if (this.props.navigateFrom && this.props.navigateFrom == BULK_FLOW.BULK_INVITES) {
            newPastedSource = this.props.pastedDetails.map((record) => {
                let contactId = record.contactId;
                let filtered = reloadContactData.filter(a => a.contactId == contactId);
                if (filtered && filtered.length && filtered.length > 0) {
                    let { pastedItem, resItem } = this.modifyRecordForBothGrid_Invites(record, reloadContactData,'reload');
                    matchedRecords.push(resItem);
                    return pastedItem;
                } else {
                    //get previous record
                    let filtered = this.state.matchingDataSource.filter(a => a.contactId == contactId);
                    if (filtered && filtered.length && filtered.length > 0) {
                        matchedRecords.push(filtered[0]);
                    }
                    return record;

                }

            });
        } else if (this.props.navigateFrom && this.props.navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS) {

        }

        updatedPastedSource = newPastedSource;
        this.setState({
            matchingDataSource: matchedRecords, //set in state for grid updates and also send to parent for further updates
            loadPanelVisible: false
        }, () => {
            this.dataGridRef?.instance?.clearSelection(); //previous if any
            this.dataGridRef?.instance?.selectAll();  //to trigger onSelectionChanged and select default rows on conditions
            this.props.updateFoundRecords(matchedRecords, updatedPastedSource)
        })
    }

    fetchContactDetails = () => {
        this.setState({
            loadPanelVisible: true,
        });
        let reqObj = this.props.pastedDetails.map(a => a.email);
        this.matrixGridService
            .fetchContactDetails(reqObj)
            .then(this.handleFetchContactSuccess)
            .catch(this.handleContactError);
    }


    initializeResponseMatchingRowItem = (pastedRecord: BulkInviteRowItem) => {
        let resItem: BulkInviteMatchingRowItem = {
            name: pastedRecord.name,
            email: pastedRecord.email,
            itemMatched: false,
            emailMatched: false,
            providerMatched: false,
            provider: pastedRecord.provider,
            providerId: pastedRecord.providerId,
            providerId_fromAPI: '',
            provider_fromAPI: '',
            userRole_fromAPI: '',
            contactId: '',
            contactType: '',
            contactTypeId: '',
            contactActive: false,
            userId: null,
            userActive: null,
            userRole: pastedRecord.userRole,
            userRoleId: null,
            canInviteUser: false,
            canCRUDUser: false,
        }
        return resItem;
    }

    convertMatchedResponseToRowItem = (pastedRecord: BulkInviteRowItem, responseItem: BulkInviteContactResponse) => {
        let resItem: BulkInviteMatchingRowItem = {
            name: responseItem.contactName,
            email: responseItem.contactEmail,
            itemMatched: false,
            emailMatched: false,
            providerMatched: false,
            provider: pastedRecord.provider,
            providerId: pastedRecord.providerId,
            providerId_fromAPI: responseItem.providerId,
            provider_fromAPI: responseItem.provider,
            contactId: responseItem.contactId,
            contactType: responseItem.contactType,
            contactTypeId: responseItem.contactTypeId,
            contactActive: responseItem.contactActive,
            userId: responseItem.userId,
            userActive: responseItem.userActive,
            userRole: pastedRecord.userRole,
            userRole_fromAPI: responseItem.userRole,
            userRoleId: responseItem.userRoleId,
            canInviteUser: false,
            canCRUDUser: false,
        }
        return resItem;

    }

    // modifyRecordForBothGrid = (pastedRecord: BulkInviteRowItem, apiRecords: BulkInviteContactResponse[]) => {
    //     let pastedItem: BulkInviteRowItem = { ...pastedRecord, itemFound: false, contactId: '', isUserRecordOk: false, itemInvited: false };
    //     let resItem: BulkInviteMatchingRowItem = this.initializeResponseMatchingRowItem(pastedRecord);

    //     let itemMatched = apiRecords.filter((resData: BulkInviteContactResponse) => {
    //         return (pastedRecord.email == resData.contactEmail && pastedRecord.providerId == resData.providerId);
    //     });

    //     let emailFound = apiRecords.filter((resData: BulkInviteContactResponse) => {
    //         return (pastedRecord.email == resData.contactEmail);
    //     });

    //     if (itemMatched && itemMatched.length && itemMatched.length > 0) {
    //         let matchedRes = itemMatched[0];
    //         pastedItem = { ...pastedItem, itemFound: true, isUserRecordOk: this.checkUserRecordOk(matchedRes), contactId: matchedRes.contactId }
    //         resItem = { ...this.convertMatchedResponseToRowItem(pastedRecord, matchedRes), itemMatched: true, providerMatched: true, emailMatched: true };

    //     } else if (emailFound && emailFound.length && emailFound.length > 0) {
    //         let matchedRecord = emailFound[0];
    //         pastedItem = { ...pastedItem, itemFound: false, contactId: matchedRecord.contactId }
    //         //resItem = {...resItem,...matchedRecord,itemMatched: false, emailMatched:true};
    //         resItem = { ...this.convertMatchedResponseToRowItem(pastedRecord, matchedRecord), itemMatched: false, emailMatched: true };

    //         if (pastedRecord.providerId == matchedRecord.providerId) {
    //             resItem = {
    //                 ...resItem,
    //                 providerMatched: true
    //             };
    //         } else {
    //             resItem = {
    //                 ...resItem,
    //                 providerMatched: false
    //             };
    //         }
    //     }

    //     return { pastedItem, resItem };
    // }

    modifyRecordForBothGrid_Invites = (pastedRecord: BulkInviteRowItem, apiRecords: BulkInviteContactResponse[],flow:string) => {
        let pastedItem: BulkInviteRowItem = { ...pastedRecord, itemFound: false, contactId: '', isUserRecordOk: false, itemInvited:false };
        if(flow && flow == 'reload'){
            pastedItem = {...pastedItem,...{itemInvited:pastedRecord.itemInvited ? true :false}};
        }
        
        let resItem: BulkInviteMatchingRowItem = this.initializeResponseMatchingRowItem(pastedRecord);
        let matchedRecord;

        let emailFound = apiRecords.filter((resData: BulkInviteContactResponse) => {
            return (pastedRecord.email == resData.contactEmail);
        });
        // if (emailFound && emailFound.length) {
        //     if (emailFound.length == 1) {
        //         matchedRecord = emailFound[0];
        //     } else if (emailFound.length > 1) {
        //         let isUserActive = emailFound.filter(a => a.userActive);
        //         if (isUserActive && isUserActive.length) {
        //             if (isUserActive.length == 1) {
        //                 matchedRecord = isUserActive[0];
        //             } else if (isUserActive.length > 1) {
        //                 let isContactActive = isUserActive.filter(a=>a.contactActive);
        //                 if(isContactActive.length){
        //                     if(isContactActive.length == 1){
        //                        matchedRecord = isContactActive[0];

        //                     } else if(isContactActive.length > 1){
        //                         let isMatchedProvider = isContactActive.filter(a => pastedRecord.providerId == a.providerId);
        //                         if (isMatchedProvider && isMatchedProvider.length && isMatchedProvider.length > 0) {
        //                             matchedRecord = isMatchedProvider[0];
        //                         } else {
        //                             matchedRecord = isContactActive[0];
        //                         }
        //                     }
        //                 } else {
        //                   matchedRecord = isContactActive[0];
        //                 }
        //             }
        //         } else {
        //             let isContactActive = emailFound.filter(a => a.contactActive);
        //             if (isContactActive && isContactActive.length) {
        //                 if (isContactActive.length == 1) {
        //                     matchedRecord = isContactActive[0];
        //                 } else if (isContactActive.length > 1) {
        //                     let isMatchedProvider = isContactActive.filter(a => pastedRecord.providerId == a.providerId);
        //                     if (isMatchedProvider && isMatchedProvider.length && isMatchedProvider.length > 0) {
        //                         matchedRecord = isMatchedProvider[0];
        //                     } else {
        //                         matchedRecord = isContactActive[0];
        //                     }

        //                 }
        //             } else {
        //                 matchedRecord = emailFound[0];
        //             }
        //         }
        //     }
        // }


        if (emailFound && emailFound.length) {
            if (emailFound.length == 1) {
                matchedRecord = emailFound[0];
            } else if (emailFound.length > 1) {
                let isContactActive = emailFound.filter(a => a.contactActive);
                if (isContactActive && isContactActive.length) {
                    if (isContactActive.length == 1) {
                        matchedRecord = isContactActive[0];
                    } else if (isContactActive.length > 1) {
                        let isProviderMatch = isContactActive.filter(a => a.providerId == pastedItem.providerId);
                        if (isProviderMatch.length) {
                            if (isProviderMatch.length == 1) {
                                matchedRecord = isProviderMatch[0];

                            } else if (isProviderMatch.length > 1) {
                                let isUserActive = isProviderMatch.filter(a => a.userActive);
                                if (isUserActive.length && isUserActive.length > 0) {
                                    matchedRecord = isUserActive[0];

                                } else {
                                    matchedRecord = isProviderMatch[0]
                                }
                            }
                        } else {
                            matchedRecord = isContactActive[0];
                        }
                    }
                } else {
                    matchedRecord = emailFound[0];
                }
            }
        }

        if (matchedRecord && matchedRecord.contactId) {
            pastedItem = { ...pastedItem, itemFound: false, contactId: matchedRecord.contactId }
            resItem = { ...this.convertMatchedResponseToRowItem(pastedRecord, matchedRecord), itemMatched: false, emailMatched: true };

            if (pastedRecord.providerId == matchedRecord.providerId) {
                pastedItem = { ...pastedItem, itemFound: true }
                resItem = {
                    ...resItem,
                    itemMatched: true,
                    providerMatched: true,
                };
            } else {
                resItem = {
                    ...resItem,
                    providerMatched: false
                };
            }
            pastedItem = { ...pastedItem, isUserRecordOk: this.isUserRecordOk(resItem) }
            resItem = { ...resItem, canInviteUser: this.isSelectableForInvites(resItem), canCRUDUser: this.isSelectableForMissingUsers(resItem) }
        }


        return { pastedItem, resItem };
    }

    modifyRecordForBothGrid_Contacts = (pastedRecord: BulkInviteRowItem, apiRecords: BulkInviteContactResponse[]) => {
        let pastedItem: BulkInviteRowItem = { ...pastedRecord, itemFound: false, contactId: '', isUserRecordOk: false, itemInvited: false };
        let resItem: BulkInviteMatchingRowItem = this.initializeResponseMatchingRowItem(pastedRecord);
        let matchedRecord: any = {};

        let emailFound = apiRecords.filter((resData: BulkInviteContactResponse) => {
            return (pastedRecord.email == resData.contactEmail);
        });

        if (emailFound && emailFound.length && emailFound.length > 0) {
            matchedRecord = emailFound[0];
        }
        if (matchedRecord && matchedRecord.contactId) {
            pastedItem = { ...pastedItem, itemFound: true, contactId: matchedRecord.contactId }
            resItem = { ...this.convertMatchedResponseToRowItem(pastedRecord, matchedRecord), itemMatched: true, emailMatched: true };

            if (pastedRecord.providerId == matchedRecord.providerId) {
                pastedItem = { ...pastedItem, itemFound: true }
                resItem = {
                    ...resItem,
                    //itemMatched: true,
                    providerMatched: true,
                };
            } else {
                resItem = {
                    ...resItem,
                    providerMatched: false
                };
            }
            pastedItem = { ...pastedItem, isUserRecordOk: this.isUserRecordOk(resItem) }
            //resItem = { ...resItem, canInviteUser: this.isSelectableForInvites(resItem), canCRUDUser: this.isSelectableForMissingUsers(resItem) }
        }
        return { pastedItem, resItem };
    }


    handleFetchContactSuccess = (response: any) => {
        let contactData = response?.data?.data || []
        let matchedRecords: BulkInviteMatchingRowItem[] = [];
        let updatedPastedSource = this.props.pastedDetails;

        let newPastedSource: any[] = [];
        if (this.props.navigateFrom && this.props.navigateFrom == BULK_FLOW.BULK_INVITES) {
            newPastedSource = this.props.pastedDetails.map((record) => {
                let { pastedItem, resItem } = this.modifyRecordForBothGrid_Invites(record, contactData,'fetch');

                matchedRecords.push(resItem);
                return pastedItem;
            });
        } else if (this.props.navigateFrom && this.props.navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS) {
            newPastedSource = this.props.pastedDetails.map((record) => {
                let { pastedItem, resItem } = this.modifyRecordForBothGrid_Contacts(record, contactData);

                matchedRecords.push(resItem);
                return pastedItem;
            });
        }

        updatedPastedSource = newPastedSource;
        this.setState({
            matchingDataSource: matchedRecords, //set in state for grid updates and also send to parent for further updates
            loadPanelVisible: false
        }, () => {
            this.dataGridRef?.instance?.clearSelection(); //previous if any
            this.dataGridRef?.instance?.selectAll();  //to trigger onSelectionChanged and select default rows on conditions
            this.props.updateFoundRecords(matchedRecords, updatedPastedSource)
        })
    }

    handleContactError = (err: any) => {
        let matchedRecords: any[] = [];
        let updatedPastedSource = this.props.pastedDetails;
        this.setState({
            loadPanelVisible: false,
            matchingDataSource: matchedRecords,
            errorMessage:
                err.response && err.response.data && err.response.data.error
                    ? JSON.parse(JSON.stringify(err.response.data.error))
                    : null, // For capturing response at the time of exception and showing error message
        }, () => {
            this.dataGridRef?.instance?.clearSelection(); //previous if any
            //this.dataGridRef?.instance?.selectAll();
            this.props.updateFoundRecords(matchedRecords, updatedPastedSource)
        });
    };

    getMissingUserRecords = () => {
        let missingUsers: any[] = [];
        this.state.matchingDataSource.forEach((item: any) => {
            if (this.isSelectableForMissingUsers(item)) {
                missingUsers.push(item);
            }
        });
        return missingUsers;
    }
    getInviteUserRecords = () => {
        let users: any[] = [];
        this.state.matchingDataSource.forEach((item: any) => {
            if (this.isSelectableForInvites(item)) {
                users.push(item);
            }
        });
        return users;
    }

    onSelectionChanged = (e: SelectionChangedEvent) => {

        const deselectRowKeys: number[] = [];
        e.selectedRowsData.forEach((item: BulkInviteMatchingRowItem) => {
            // if (!this.isSelectableForInvites(item) && !this.isSelectableForMissingUsers(item)) {
            //     deselectRowKeys.push(e.component.keyOf(item));
            // }
            if (this.props.navigateFrom == BULK_FLOW.BULK_INVITES) {
                if (!item.canInviteUser && !item.canCRUDUser) {
                    deselectRowKeys.push(e.component.keyOf(item));
                }
            }
            if (this.props.navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS) {
                if (!this.isSelectableForDuplicates(item)) {
                    deselectRowKeys.push(e.component.keyOf(item));
                }
            }

        });
        if (deselectRowKeys.length) {
            e.component.deselectRows(deselectRowKeys);
        }

        this.selectionRef.current.checkBoxUpdating = true;
        const selectAllCheckBox = this.selectionRef.current.selectAllCheckBox;
        selectAllCheckBox?.option("value", this.isSelectAll(e.component));
        this.selectionRef.current.checkBoxUpdating = false;

        let selectedRows = e.selectedRowsData;
        // let missingUsers: any[] = this.getMissingUserRecords();
        // let invitesUsers :any[] = this.getInviteUserRecords();

        // this.props.setSelectedRows ? this.props.setSelectedRows(selectedRows, invitesUsers, missingUsers) : null;
        this.props.setSelectedRows ? this.props.setSelectedRows(selectedRows) : null;

    };
    // onCellPrepared = (cellEvent: any) => {
    //     // //console.log(editEvent.parentType,'editEvent.parentType');
    //     // if (editEvent.parentType === "filterRow" && editEvent.dataField === "businessEntityId" && editEvent.caption?.toLowerCase() == 'provider') {
    //     //     editEvent.editorName = "dxTextBox"
    //     //     editEvent.editorOptions.valueChangeEvent = "keyup";
    //     //     // editEvent.editorOptions.calculateFilterExpression = function calculateFilterExpression(filterValue:any, selectedFilterOperation:any, target:any){
    //     //     //     alert(target);

    //     //     // }
    //     // }

    //     if (cellEvent.rowType === "data" && cellEvent.data && !cellEvent.data.itemMatched) {
    //         cellEvent.cellElement.find('.dx-select-checkbox').dxCheckBox("instance").option("disabled", true);
    //         cellEvent.cellElement.off();
    //     }
    // }

    isSelectAll = (dataGrid: any) => {
        let items: any[] = [];
        dataGrid.getDataSource().store().load().then((data: any) => {
            items = data;
        });
        // let selectableItems = items.filter(this.isSelectableForInvites || this.isSelectableForMissingUsers);
        let selectableItems = [];
        if (this.props.navigateFrom == BULK_FLOW.BULK_INVITES)
            selectableItems = items.filter(a => a.canInviteUser || a.canCRUDUser);

        if (this.props.navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS)
            selectableItems = items.filter(a => this.isSelectableForDuplicates(a));

        let selectedRowKeys = dataGrid.option("selectedRowKeys");
        if (!selectedRowKeys || !selectedRowKeys.length) {
            return false;
        }
        return selectedRowKeys.length >= selectableItems.length ? true : undefined;
    }

    //eligible to send invites
    isSelectableForInvites = (item: BulkInviteMatchingRowItem) => {
        let selectable = false;
        let navigateFrom = this.props.navigateFrom || '';
        if (navigateFrom == BULK_FLOW.BULK_INVITES && item && item.itemMatched && item.contactActive && item.userId && item.userActive) {
            selectable = true;
        }
        return selectable;
    }

    isSelectableForMissingUsers = (item: BulkInviteMatchingRowItem) => {
        let selectable = false;
        let navigateFrom = this.props.navigateFrom || '';
        if (navigateFrom == BULK_FLOW.BULK_INVITES) {
            // if( item && item.itemMatched && item.contactActive && item.contactId && (!item.userId || !item.userActive) && 
            //     (item.userRole && item.userRole != item.userRole_fromAPI)) {
            if (item && item.itemMatched && item.contactActive && item.contactId && (!item.userId || !item.userActive ||
                (item.userRole && item.userRole != item.userRole_fromAPI))) {
                selectable = true;
            }

        }
        return selectable;
    }

    isSelectableForDuplicates = (item: BulkInviteMatchingRowItem) => {
        let selectable = false;
        let navigateFrom = this.props.navigateFrom || '';
        if (navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS && item && !item.emailMatched && item.providerId && item.userRole) {
            selectable = true;
        }
        return selectable;
    }

    isUserRecordOk = (item: BulkInviteMatchingRowItem) => {
        let isOk = false;
        if (this.props.navigateFrom && this.props.navigateFrom == BULK_FLOW.BULK_INVITES) {
            if (item && item.itemMatched && item.contactActive && item.userId && item.userActive && (item.userRole && item.userRole_fromAPI && item.userRole == item.userRole_fromAPI)) {
                isOk = true;
            }
        } else if (this.props.navigateFrom && this.props.navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS) {
            if (item && item.userId && item.userActive) {
                isOk = true;
            }
        }

        return isOk;
    }

    // isMissingUser = (item: BulkInviteMatchingRowItem) => {
    //     let isMissingUser = false;
    //     if (item && item.itemMatched && item.contactActive && item.contactId && (!item.userId || !item.userActive)) { //condition for role changes
    //         isMissingUser = true;
    //     }
    //     return isMissingUser;
    // }

    // checkUserRecordOk = (item: any) => {
    //     let recordOk = false;
    //     if (item && item.contactId && item.contactActive && item.userId && item.userActive) {
    //         recordOk = true;
    //     }
    //     return recordOk;
    // }
    onEditorPreparing = (e: any) => {
        // if (editEvent.parentType === "dataRow" && editEvent.row && !this.isSelectable(editEvent.row.data)) {
        //     editEvent.editorOptions.disabled = true;
        // }

        let dataGrid = e.component;
        if (e.type !== 'selection') return;
        if (this.props.navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS) {
            if (e.parentType === 'dataRow' && e.row && !this.isSelectableForDuplicates(e.row.data)) {
                //e.editorOptions.disabled = true;
                e.editorElement?.setAttribute('disabled', true);
            }
        }
        if (this.props.navigateFrom == BULK_FLOW.BULK_INVITES) {
            if (e.parentType === 'dataRow' && e.row && !this.isSelectableForInvites(e.row.data) && !this.isSelectableForMissingUsers(e.row.data)) {
                //e.editorOptions.disabled = true;
                e.editorElement?.setAttribute('disabled', true);
            }
        }

        if (e.parentType === "headerRow") {
            e.editorOptions.onInitialized = (e: any) => {
                if (e.component)
                    this.selectionRef.current.selectAllCheckBox = e.component;
            };
            e.editorOptions.value = this.isSelectAll(dataGrid);
            e.editorOptions.onValueChanged = (e: any) => {
                if (!e.event) {
                    if (e.previousValue && this.selectionRef.current.checkBoxUpdating)
                        e.component.option("value", e.previousValue);
                    return;
                }
                if (this.isSelectAll(dataGrid) === e.value)
                    return;
                e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
                e.event.preventDefault();
            }
        }
    }

    colorStatusForEntity = (cellInfo: any) => {
        let itemMatched = cellInfo?.data?.data?.itemMatched;
        let emailMatched = cellInfo?.data?.data?.emailMatched;
        let providerMatched = cellInfo?.data?.data?.providerMatched;
        let provider = '';

        if (itemMatched) {
            provider = cellInfo?.data?.data?.provider || cellInfo?.data?.data?.provider_fromAPI || '';
            return (
                <>
                    <div className="new-email" style={{ fontWeight: 'bold', color: '#32c5ff', padding: '0.5rem' }}>
                        <div>{`${provider}`}</div>
                    </div>

                </>
            )
        } else if (emailMatched && !providerMatched) {
            provider = cellInfo?.data?.data?.provider_fromAPI || '';
            return (
                <>
                    <div style={{ fontWeight: 'bold', color: 'red', padding: '0.5rem' }}>
                        <div>{`${provider}`}</div>
                    </div>

                </>
            )
        }
        return (
            <div style={{ padding: '0.5rem' }}></div>
        )
    }

    providerFoundCellValue = (cellInfo: any) => {
        // let itemMatched = cellInfo?.itemMatched;
        // let emailMatched = cellInfo?.emailMatched;
        // let providerMatched = cellInfo?.providerMatched;
        let provider = cellInfo?.provider_fromAPI || '';

        // if (itemMatched) {
        //     provider = cellInfo?.provider || cellInfo?.provider_fromAPI || '';
        // } else if (emailMatched && !providerMatched) {
        //     provider = cellInfo?.provider_fromAPI || '';
        // }
        return provider;
    }


    colorStatusForDuplicate = (cellInfo: any) => {
        let emailMatched = cellInfo?.data?.data?.emailMatched;

        if (emailMatched) {
            return (
                <>
                    <div style={{ fontWeight: 'bold', color: 'red', padding: '0.5rem' }}>
                        <div>{`Yes`}</div>
                    </div>

                </>
            )
        }
        return (
            <>
                <div style={{ fontWeight: 'bold', color: '#32c5ff', padding: '0.5rem' }}>
                    <div>{`No`}</div>
                </div>

            </>
        )
    }

    duplicateFoundCellValue = (cellInfo: any) => {
        // let itemMatched = cellInfo?.itemMatched;
        let emailMatched = cellInfo?.emailMatched;
        let val = 'No';

        if (emailMatched) {
            val = 'Yes';
        }
        return val;
    }

    displayBooleanContent = (Val: any) => {
        let valToShow: string = "";
        //if (Val !== null) {
        if (Val) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        //}
        return (
            <div style={{ margin: '10px', minHeight: '10px' }}>
                {
                    valToShow == 'Yes' ? <b><span>{valToShow}</span></b> : <div style={{ fontWeight: 'bold', color: 'red', padding: '0.5rem' }}>{valToShow}</div>
                }
            </div>
        );
    }

    calculateBooleanContent = (Val: any) => {
        let valToShow: string = '';
        if (Val) {
            valToShow = 'Yes';
        }
        else {
            valToShow = 'No';
        }
        return valToShow;
    }


    render() {
        let isContactFlow = this.props.navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS;
        let isInviteFlow = this.props.navigateFrom == BULK_FLOW.BULK_INVITES;
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                <div className="row">
                    <div className="col-md">
                        <DataGrid dataSource={this.state.matchingDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            allowColumnReordering={true}
                            columnMinWidth={100}
                            onSelectionChanged={this.onSelectionChanged}
                            //onCellPrepared={this.onCellPrepared}
                            onEditorPreparing={this.onEditorPreparing}
                            ref={(ref) => { this.dataGridRef = ref; }}
                            remoteOperations={false}
                            // keyExpr={'contactId'}
                        >
                            <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always"></Selection>
                            {/* <SearchPanel visible={true} placeholder={"Search"} /> */}
                            {/* <GroupPanel visible={true} /> */}
                            <FilterPanel visible={true} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            <Export enabled={true} />
                            <Column
                                dataField="name"
                                caption="NAME" />
                            <Column
                                dataField="email"
                                caption="EMAIL" />
                            <Column
                                dataField="providerId"
                                caption="PROVIDER ID" />
                            <Column
                                dataField="provider"
                                caption="PROVIDER" />
                            <Column
                                dataField="provider_fromAPI"
                                caption="PROVIDER FOUND"
                                cellComponent={(cellInfo: any) => { return this.colorStatusForEntity(cellInfo) }}
                                calculateCellValue={(cellInfo: any) => { return this.providerFoundCellValue(cellInfo) }} />
                            {isContactFlow ? <Column
                                dataField="emailMatched"
                                caption="DUPLICATE FOUND"
                                cellComponent={(cellInfo: any) => { return this.colorStatusForDuplicate(cellInfo) }}
                                calculateCellValue={(cellInfo: any) => { return this.duplicateFoundCellValue(cellInfo) }} /> : null}
                            <Column
                                dataField="contactId"
                                caption="CONTACT ID" />
                            {isInviteFlow ? <Column
                                dataField="contactType"
                                caption="CONTACT ROLE" /> : null}
                            <Column
                                dataField="contactActive"
                                caption="CONTACT ACTIVE"
                                cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.contactActive) }}
                                calculateDisplayValue={(rowData: any) => {
                                    return this.calculateBooleanContent(rowData?.contactActive)
                                }}
                                calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.contactActive) }} />
                            <Column
                                dataField="userId"
                                caption="USER ID" />
                            {isInviteFlow ? <Column
                                dataField="userRole_fromAPI"
                                caption="USER ROLE" /> : null}
                            <Column
                                dataField="userActive"
                                caption="USER ACTIVE"
                                cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.userActive) }}
                                calculateDisplayValue={(rowData: any) => {
                                    return this.calculateBooleanContent(rowData?.userActive)
                                }}
                                calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.userActive) }} />
                        </DataGrid>
                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation()(MatchingBulkInviteGrid);